import React from 'react'
import Logo from './favicon.jpg'
import { BiPhoneCall, BiSearch } from "react-icons/bi"
import { LuPhoneCall } from 'react-icons/lu'
import { Link } from 'react-router-dom'

const Header = () => {
  
  return (
    <div className='md:px-5 py-4 shadow-md'>
        <div className='lg:flex gap-[50%]'>
            <div className='lg:flex grid justify-center gap-2 items-center'>
                <img src={Logo} className='lg:ml-0 ml-[28%]' alt="logo" />
                <p className='font-bold text-lg lg:text-2xl font-sans text-blue-500'
                style={{letterSpacing: 0}}
                >CENTRALIZED PAYMENT SYSTEM</p>
            </div>
            <ul className='md:flex  justify-center items-center gap-2 lg:gap-10'>
                <li className='flex cursor-pointer mt-3 md:mt-0 justify-center gap-5'>
                  <Link target='_blank' to="https://wa.me/+2348169011585" className='font-bold lg:text-sm flex items-center bg-gray-100 p-4 px-10 rounded-full shadow-md text-center  hover:scale-125 hover:text-blue-500 duration-500 text-gray-600'>Help &nbsp; <LuPhoneCall />
                  </Link>
                  {/* <li className='font-bold text-lg bg-gray-200 p-1 rounded-full w-20 shadow-md text-center hover:scale-125 hover:text-blue-500 duration-500 text-gray-600'>Help..?</li> */}
                </li>
            </ul>
        </div>
    </div>
  )
}

export default Header