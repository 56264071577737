import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "./Components/Header";
// import Home from "./Components/Home";
import Homes from "./Home";

function App() {
  return (
    <div className="App">
            <>
            <Header  />
            <Routes>
                <Route path="/" element={<Homes  />} />
            </Routes>
            </>
    </div>
  );
}

export default App;
